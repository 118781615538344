.contact-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30%;
    padding-left: 30px;
    padding-right: 30px;
}
  .form-control{
    width: 600px;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    color: #000;
    font-size: 18px;
    margin-bottom: 20px;
    
 
   
  }
 
  



  .form-input{
    margin-right: auto;
    margin-top:  auto;
    margin-bottom: auto;

  }
  input{
    height: 45px;
  }
  form .submit{
    background: #9B2515;
    border-color: transparent;
    color: #FDE0CA ;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    height: 50px;
    margin-top: 20px;
    border-radius: 25px;
  }
  form .submit:hover{
    background-color: #9B2515;
    opacity: 90%;
    cursor: pointer;
  }
  #sec-5m{
    display: none;
  }

  h1{
    color: #9B2515;
    font-family: Nordeco;
    font-size: 32px;
    font-weight: 400;

  }
  p{
    color:#5E4C40;
    font-family: Nordeco;
    font-size: 20px;
    font-weight: 200;
   
  }
  /* Extra Small devices (phones) */
  @media (max-width: 480px) {
    p{
      color:#5E4C40;
      font-family: Nordeco;
      font-size: 19px;
      font-weight: 200;
     
    }
  }
@media (max-width: 576px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    gap: 1rem; /* Reduce the gap between elements */
  }

  .form-control {
    width: 90%; /* Adjust the width for better fit on mobile */
    margin-left: 0; 
   
  }

  .form-data {
    margin-bottom: 1rem;
   
  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
    width: 100%;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    gap: 0rem; /* Reduce the gap between elements */
  
  }

  .form-control {
   max-width: 90%;
    margin-left: 5%; 
   
  }

  .form-data {
    margin-bottom: 1rem;

  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
    width: 100%;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    gap: 0rem; /* Reduce the gap between elements */
  }

  .form-control {
   
    margin-left: 0; 
   
  }

  .form-data {
    margin-bottom: 1rem;
    margin-left:15%; /* Add some margin for spacing */
  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-form {
    gap: 0px;
    padding: 20px;
  }
  .form-control {
    font-size: 20px;
  }
  h1 {
    font-size: 48px;
  }
}
@media (min-width: 1200px) {
  .contact-form {
    gap: 30%;
    padding: 30px;
   
  }
  .form-control {
    font-size: 20px;
   
  }
  h1 {
    font-size: 48px;
  }
  .form-data{
    font-size:2rem;
    padding-left: 30px;
    padding-right: 30px;
  }
}