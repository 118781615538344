.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: #ffffff;
  padding: 0 20px;
}

.navbar-items {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.navbar-logomobile {
  display: none;
}
.navbar-items-left,
.navbar-items-right {
  display: flex;
  gap: 10px;
}

.navbar-logo {
  margin: 0 20px;
}

.navbar-logo img {
  max-height: 100px;
}

.navbar a {
  text-decoration: none;
  background-image: linear-gradient(to right, #E56731, #E56731 50%, #9B2515 50%) !important;
  background-size: 200% 100%; 
  background-position: -100%;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  z-index: 999999999;
  
}
select {
  -webkit-appearance: none;

  border:solid 1px #555;
  border-radius:3px;
  padding:5px;
}

/* Hamburger menu */
.navbar-hamburger {
  order: 2;
  display: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.hamburger-bar {
  display: none;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
}
.navbar a:hover {
  background-position: 0%;
}

.open .hamburger-bar:nth-child(1) {
  transform: translateY(8px) rotate(-45deg);
}

.open .hamburger-bar:nth-child(2) {
  opacity: 0;
}

.open .hamburger-bar:nth-child(3) {
  transform: translateY(-8px) rotate(45deg);
}
.mobile-menu-items{
  display: none;
}
#menuDropdown{
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
color: #9B2515 ;
font-size: 1.1rem;
text-align: center; 
}


/* Show the hamburger menu for screens below 480px */
@media (max-width: 480px) {
  .custom-dropdown {
    margin: 0;
    padding: 0;
  }
  #mobileMenuDropdown{
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   padding-right: 0;
   align-items: center;
   text-align: center; 
  color: #9B2515 ;
  font-size: 2rem;
  }
  
  .navbar-hamburger {
    display: block;
    position: absolute; /* Position the hamburger menu icon on the top right */
    top: 18px; /* Position the hamburger menu icon 10px from the top */
    right: 20px;
    margin-right: 0;
  }
  .hamburger-bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
  }

  .navbar-items {
    display: none;
  }
.navbar-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-logomobile {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
  
  .navbar-logomobile img {
    max-height: 70px;

  }
  /* Adjust the mobile menu to display on top right */
  .mobile-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  .mobile-menu-wrapper.active {
    opacity: 1;
    pointer-events: auto;
  }

  .mobile-menu-items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center; /* Center the mobile menu items */
    font-size: 2rem;
  }

  /* Adjust the hamburger icon when the mobile menu is open */
  .navbar.mobile-menu-open .navbar-hamburger {
    display: none;
  }

  .navbar.mobile-menu-open .mobile-menu-wrapper .hamburger-bar {
    display: block;
  }

  /* Styling the cross icon */
  .mobile-menu-close {
    position: absolute; /* Position the closing button relative to its containing element */
    top: 10px; /* Position the closing button 10px from the top */
    right: 10px; /* Position the closing button 10px from the right */
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    cursor: pointer;
  }

  .mobile-menu-close .hamburger-bar {
    background-color: #333;
    width: 25px;
    height: 3px;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu-close .hamburger-bar:nth-child(1) {
    transform: rotate(45deg) translate(10px, 1px);
  }

  .mobile-menu-close .hamburger-bar:nth-child(2) {
    transform: rotate(-45deg) translate(-18px, -8px);
  }

  .mobile-menu-close .hamburger-bar:nth-child(3) {
    opacity: 0;
  }
 
}
@media (min-width: 1200px) {
  .navbar-items{
    font-size:1.1rem;
  }
}