

.header {
    display: flex;
    align-items: center; /* To vertically center the image */
  }
  
  .headerimg {
    width: 85%;
    height: auto;
    margin-left: auto; /* Pushes the image to the right */
  }

    .eventmobile{
    display: none;
    }
 
    .image-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      max-width: 250px; /* Adjust the card width as needed */
height: 300px;
    }
    
    .image-card img {
      width: 100%;
      height: auto;
    }
    
    .card-content {
      padding: 10px;
      text-align: center;
    }
    
    .card-title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 8px;
    }
    
    .card-description {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 12px;
    }
    
    .card-button {
      background-color: #8F4833;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
    }
    
  
 .upcoming-event{
color: #73806E;
font-size: 2.5rem;
margin-left: -32% !important;
display: flex;
align-items: left !important;

 }

 .event-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  padding-left: 30px;
  padding-right: 30px;


}

.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Gap between cards */
  margin-top: 20px; /* Add spacing between header and cards */
}

.card {
  width: 100%; /* Occupy the full width of each grid cell */
  margin: 0px; /* Remove default margin */
}
.see-more-button{
  background-color: #73806E;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      margin: 20px;
}
@media (max-width: 480px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 32px;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
    
   
}
@media (min-width: 480px) and (max-width: 576px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 1.5rem;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
   
}
@media (min-width: 576px) and (max-width: 768px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 1.5rem;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
}
