.carousel-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.paragraph {
  margin-bottom: 1rem ;
  font-family: Nordeco;
  font-size: 24px ;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;
}

.previous-button {
  margin-bottom: 1rem;
  border: none;
  background: transparent;
}

.next-button {
  margin-top: 1rem;
  border: none;
  background: transparent;
}

.comments h1 {
  font-family: Nordeco;
  font-size: 32px;
  font-weight: 400;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: center;
  color: #9B2515;
}

.comments {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align the content in the center horizontally */
  justify-content: center; /* Align the content in the center vertically */
  height: 35vh;
  padding-right:30px;
  padding-left: 30px;
  background: #FDE0CA;
}

.icon-comments {
  font-size: 34px; /* Adjust the font size as per your requirement */
  color: #000 !important;
}

.icon-comments:hover {
  cursor: pointer;
}

/* Extra Small devices (phones) */
@media (max-width: 576px) {
  .comments{
    padding-left: 30px;
    padding-right: 30px;
  }
  .paragraph {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 24px; /* Adjust line height for smaller screens */
    width: 100%; /* Make the text take the full width on mobile */
  }

  .comments h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
    line-height: 40px; /* Adjust line height for smaller screens */
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .paragraph {
    font-size: 22px;
    line-height: 28px;
    width: 90%;
  }

  .comments h1 {
    font-size: 48px;
    line-height: 60px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .paragraph {
    font-size: 26px ;
    line-height: 32px;
    width: 80%;
  }

  .comments h1 {
    font-size: 48px;
    line-height: 68px;
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .paragraph {
    font-size: 1.3rem;
    line-height: 34px;
    width: 70%;
  }

  .comments h1 {
    font-size: 2.8rem;
    line-height: 72px;
  }
}