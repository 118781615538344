.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.img-container img {
    max-width: 100%;
    height: auto;
}

.voorwaarden-info {
    background-color: #FDE0CA;
    margin-bottom: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.voorwaarden-info p {
    font-size: 1.2rem;
    width: 70%;
}

@media (max-width: 480px) {
    .img-container,
    .voorwaarden-info {
        height: auto;
    }
    
    .voorwaarden-info p {
        width: 90%;
    }
}

@media (min-width: 480px) and (max-width: 576px) {
    .voorwaarden-info p {
        width: 80%;
    }
   
}

@media (min-width: 576px) and (max-width: 768px) {
    .voorwaarden-info p {
        width: 75%;
    }
   
}

@media (min-width: 768px) and (max-width: 991px) {
    .voorwaarden-info p {
        width: 70%;
    }
    .voorwaarden-info {
        padding: 30px; /* Adjust as needed */
    }
   
}

@media (min-width: 992px) and (max-width: 1199px) {
    .voorwaarden-info p {
        width: 65%;
    }
    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
}
