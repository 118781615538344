
.Arrangment-description{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

}
.Arrangment-background {
    position: relative;
  }
  
  .Arrangment-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .Arrangment-description {
    text-align: center;
    color: white;
    z-index: 1;
  }
  
  .Arrangment-hero {
    width: 100%;
    height: 85vh;
  }
.Arrangment-description h1{
    color: #FFF;

font-family: Nordeco;
font-size: 3rem;
font-weight: 200;
}  
.Arrangment-description p{
    color: #FFF;
width: 50%;
    font-family: Nordeco;
    font-size: 1.2rem;
    font-weight: 200;
}

.Arrangment-description button{
    border-radius: 25px;
background: #9B2515;
border: none;
color: white;
width: 200px;
height: 40px;
color: #FFF;
font-size: 20px;
font-family: Nordeco;
}
/* Extra Small devices (phones) */
@media (max-width: 480px) {
  .Arrangment-hero {
    height: 60vh; /* Adjust the height for better visibility on mobile */
  }

  .Arrangment-description h1 {
    font-size: 1.5rem !important; /* Adjust font size for smaller screens */
  }

  .Arrangment-description p {
    font-size: 0.9rem !important; /* Adjust font size for smaller screens */
    width: 100% !important;
    
  }

  .Arrangment-description button {
    width: 150px; /* Adjust button width for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
@media (max-width: 576px) {
  .Arrangment-hero {
    height: 60vh; /* Adjust the height for better visibility on mobile */
  }

  .Arrangment-description h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .Arrangment-description p {
    font-size: 1rem; /* Adjust font size for smaller screens */
   
  }

  .Arrangment-description button {
    width: 150px; /* Adjust button width for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Arrangment-description {
    padding: 30px;
  }

  .Arrangment-hero {
    height: 70vh;
  }

  .Arrangment-description h1 {
    font-size: 2.2rem;
  }

  .Arrangment-description p {
    font-size: 1.1rem;
    width: 100%;
  }

  .Arrangment-description button {
    width: 180px;
    font-size: 18px;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Arrangment-description {
    padding: 40px;
  }

  .Arrangment-hero {
    height: 80vh;
  }

  .Arrangment-description h1 {
    font-size: 2.5rem;
  }

  .Arrangment-description p {
    font-size: 1.2rem;
  }

  .Arrangment-description button {
    width: 200px;
    font-size: 20px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Arrangment-description {
    padding: 50px;
  }

  .Arrangment-hero {
    height: 90vh;
  }

  .Arrangment-description h1 {
    font-size: 2.8rem;
  }

  .Arrangment-description p {
    font-size: 1.3rem;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .Arrangment-description button {
    width: 220px;
    font-size: 22px;
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .Arrangment-description {
    padding: 60px;
  }

  .Arrangment-hero {
    height: 100vh;
  }

  .Arrangment-description h1 {
    font-size: 2rem;
  }

  .Arrangment-description p {
    font-size:1.3rem ;
  }

  .Arrangment-description button {
    width: 250px;
    font-size: 24px;
  }
}