body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: #ffffff;
  padding: 0 20px;
}

.navbar-items {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.navbar-logomobile {
  display: none;
}
.navbar-items-left,
.navbar-items-right {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.navbar-logo {
  margin: 0 20px;
}

.navbar-logo img {
  max-height: 100px;
}

.navbar a {
  text-decoration: none;
  background-image: linear-gradient(to right, #E56731, #E56731 50%, #9B2515 50%) !important;
  background-size: 200% 100%; 
  background-position: -100%;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  z-index: 999999999;
  
}
select {
  -webkit-appearance: none;

  border:solid 1px #555;
  border-radius:3px;
  padding:5px;
}

/* Hamburger menu */
.navbar-hamburger {
  order: 2;
  display: none;
  cursor: pointer;
  padding: 10px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.hamburger-bar {
  display: none;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
}
.navbar a:hover {
  background-position: 0%;
}

.open .hamburger-bar:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(-45deg);
          transform: translateY(8px) rotate(-45deg);
}

.open .hamburger-bar:nth-child(2) {
  opacity: 0;
}

.open .hamburger-bar:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(45deg);
          transform: translateY(-8px) rotate(45deg);
}
.mobile-menu-items{
  display: none;
}
#menuDropdown{
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
color: #9B2515 ;
font-size: 1.1rem;
text-align: center; 
}


/* Show the hamburger menu for screens below 480px */
@media (max-width: 480px) {
  .custom-dropdown {
    margin: 0;
    padding: 0;
  }
  #mobileMenuDropdown{
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   padding-right: 0;
   align-items: center;
   text-align: center; 
  color: #9B2515 ;
  font-size: 2rem;
  }
  
  .navbar-hamburger {
    display: block;
    position: absolute; /* Position the hamburger menu icon on the top right */
    top: 18px; /* Position the hamburger menu icon 10px from the top */
    right: 20px;
    margin-right: 0;
  }
  .hamburger-bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
  }

  .navbar-items {
    display: none;
  }
.navbar-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-logomobile {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
  
  .navbar-logomobile img {
    max-height: 70px;

  }
  /* Adjust the mobile menu to display on top right */
  .mobile-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  .mobile-menu-wrapper.active {
    opacity: 1;
    pointer-events: auto;
  }

  .mobile-menu-items {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    align-items: center; /* Center the mobile menu items */
    font-size: 2rem;
  }

  /* Adjust the hamburger icon when the mobile menu is open */
  .navbar.mobile-menu-open .navbar-hamburger {
    display: none;
  }

  .navbar.mobile-menu-open .mobile-menu-wrapper .hamburger-bar {
    display: block;
  }

  /* Styling the cross icon */
  .mobile-menu-close {
    position: absolute; /* Position the closing button relative to its containing element */
    top: 10px; /* Position the closing button 10px from the top */
    right: 10px; /* Position the closing button 10px from the right */
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    cursor: pointer;
  }

  .mobile-menu-close .hamburger-bar {
    background-color: #333;
    width: 25px;
    height: 3px;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }

  .mobile-menu-close .hamburger-bar:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(10px, 1px);
            transform: rotate(45deg) translate(10px, 1px);
  }

  .mobile-menu-close .hamburger-bar:nth-child(2) {
    -webkit-transform: rotate(-45deg) translate(-18px, -8px);
            transform: rotate(-45deg) translate(-18px, -8px);
  }

  .mobile-menu-close .hamburger-bar:nth-child(3) {
    opacity: 0;
  }
 
}
@media (min-width: 1200px) {
  .navbar-items{
    font-size:1.1rem;
  }
}


.header {
    display: flex;
    align-items: center; /* To vertically center the image */
  }
  
  .headerimg {
    width: 85%;
    height: auto;
    margin-left: auto; /* Pushes the image to the right */
  }

    .eventmobile{
    display: none;
    }
 
    .image-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      max-width: 250px; /* Adjust the card width as needed */
height: 300px;
    }
    
    .image-card img {
      width: 100%;
      height: auto;
    }
    
    .card-content {
      padding: 10px;
      text-align: center;
    }
    
    .card-title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 8px;
    }
    
    .card-description {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 12px;
    }
    
    .card-button {
      background-color: #8F4833;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
    }
    
  
 .upcoming-event{
color: #73806E;
font-size: 2.5rem;
margin-left: -32% !important;
display: flex;
align-items: left !important;

 }

 .event-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  padding-left: 30px;
  padding-right: 30px;


}

.card-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px; /* Gap between cards */
  margin-top: 20px; /* Add spacing between header and cards */
}

.card {
  width: 100%; /* Occupy the full width of each grid cell */
  margin: 0px; /* Remove default margin */
}
.see-more-button{
  background-color: #73806E;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      margin: 20px;
}
@media (max-width: 480px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 32px;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
    
   
}
@media (min-width: 480px) and (max-width: 576px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 1.5rem;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
   
}
@media (min-width: 576px) and (max-width: 768px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
  .upcoming-event{
    color: #73806E;
    font-size: 1.5rem;
    margin-left: 5% !important;
    display: flex;
    align-items: left ;
    width: 100%;
    
     }
     .headerimg {
      display: block;
      width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px; /* Gap between cards */
    margin-top: 20px; /* Add spacing between header and cards */
  }
}


 
  
  .background img {
    width: 100%;
    height: 88%;
    object-fit: cover;
  }
  
.about{
    padding-right:30px;
    padding-left: 30px;
}

.grid-h1 {
    font-weight: 400;
    font-size: 64px;
    color: #9B2515;
   

  }
  
  .grid-p {
width: 100%;
    margin-left: 40%;
    font-size: 1.3rem ;
  }
  
  .about-img {
    /* Add the following styles to create an offset effect */
    -webkit-transform: translate(30px, 0px);
            transform: translate(30px, 0px); /* Adjust the translation values as per your preference */
  }
  .about-img1 {
    /* Add the following styles to create an offset effect */
    -webkit-transform: translate(30px, 200px);
            transform: translate(30px, 200px); /* Adjust the translation values as per your preference */
  }
  /* Extra Small devices (phones) */
@media (max-width: 576px) { 
  .grid-h1 {
    font-size: 32px; /* Adjust the heading font size for mobile */
  }

  .grid-p {
    margin-left: 0; /* Remove left margin for full-width text on mobile */
  }

  .about-images-container {
    flex-direction: column; /* Stack images below each other on mobile */
    align-items: center; /* Center the images horizontally */
  }

  .about-img1,
  .about-img {
   display:none;
  }
}
@media (min-width: 577px) and (max-width: 767px) {
  .grid-h1 {
    font-size: 32px; /* Adjust the heading font size for mobile */
  }
.about{
  display: flex;
  justify-content: center;
}
  .grid-p {
    margin-left: 0; /* Remove left margin for full-width text on mobile */
  }

  .about-images-container {
    flex-direction: column; /* Stack images below each other on mobile */
    align-items: center; /* Center the images horizontally */
  }

  .about-img1,
  .about-img {
   display:none;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 899px) {
  .grid-h1 {
    font-size: 42px;
    margin-left: 30px;
  }

  .grid-p {
    margin-left: 30px;
  }

  .about-img,
  .about-img1 {
    display: none;
    /* Revert the offset effect for images on tablet devices */
    -webkit-transform: none;
            transform: none;
  }
}

/* Large devices (desktops) */
@media (min-width: 900px) and (max-width: 1199px) {
  .grid-h1 {
    font-size: 48px;
    padding-right:30px;
    padding-left: 30px;
  }

  .grid-p {
    margin-left: 30px;
  }

  .about-img,
  .about-img1 {
    display: block;
    /* Revert the offset effect for images on desktop devices */
   
  }
  .about-img1 {
    /* Add the following styles to create an offset effect */
    -webkit-transform: translate(30px, 150px);
            transform: translate(30px, 150px); /* Adjust the translation values as per your preference */
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .grid-h1 {
    font-size: 56px;
    margin-left: 100px;
  }

  .grid-p {
    margin-left: 100px;
    font-size:1.3rem ;
  }

  .about-img,
  .about-img1 {
    display: block;
    /* Revert the offset effect for images on large desktop devices */
 
  }
}

.carousel-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.paragraph {
  margin-bottom: 1rem ;
  font-family: Nordeco;
  font-size: 24px ;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  width: 80%;
}

.previous-button {
  margin-bottom: 1rem;
  border: none;
  background: transparent;
}

.next-button {
  margin-top: 1rem;
  border: none;
  background: transparent;
}

.comments h1 {
  font-family: Nordeco;
  font-size: 32px;
  font-weight: 400;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: center;
  color: #9B2515;
}

.comments {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align the content in the center horizontally */
  justify-content: center; /* Align the content in the center vertically */
  height: 35vh;
  padding-right:30px;
  padding-left: 30px;
  background: #FDE0CA;
}

.icon-comments {
  font-size: 34px; /* Adjust the font size as per your requirement */
  color: #000 !important;
}

.icon-comments:hover {
  cursor: pointer;
}

/* Extra Small devices (phones) */
@media (max-width: 576px) {
  .comments{
    padding-left: 30px;
    padding-right: 30px;
  }
  .paragraph {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 24px; /* Adjust line height for smaller screens */
    width: 100%; /* Make the text take the full width on mobile */
  }

  .comments h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
    line-height: 40px; /* Adjust line height for smaller screens */
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .paragraph {
    font-size: 22px;
    line-height: 28px;
    width: 90%;
  }

  .comments h1 {
    font-size: 48px;
    line-height: 60px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .paragraph {
    font-size: 26px ;
    line-height: 32px;
    width: 80%;
  }

  .comments h1 {
    font-size: 48px;
    line-height: 68px;
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .paragraph {
    font-size: 1.3rem;
    line-height: 34px;
    width: 70%;
  }

  .comments h1 {
    font-size: 2.8rem;
    line-height: 72px;
  }
}

.Arrangment-description{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

}
.Arrangment-background {
    position: relative;
  }
  
  .Arrangment-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .Arrangment-description {
    text-align: center;
    color: white;
    z-index: 1;
  }
  
  .Arrangment-hero {
    width: 100%;
    height: 85vh;
  }
.Arrangment-description h1{
    color: #FFF;

font-family: Nordeco;
font-size: 3rem;
font-weight: 200;
}  
.Arrangment-description p{
    color: #FFF;
width: 50%;
    font-family: Nordeco;
    font-size: 1.2rem;
    font-weight: 200;
}

.Arrangment-description button{
    border-radius: 25px;
background: #9B2515;
border: none;
color: white;
width: 200px;
height: 40px;
color: #FFF;
font-size: 20px;
font-family: Nordeco;
}
/* Extra Small devices (phones) */
@media (max-width: 480px) {
  .Arrangment-hero {
    height: 60vh; /* Adjust the height for better visibility on mobile */
  }

  .Arrangment-description h1 {
    font-size: 1.5rem !important; /* Adjust font size for smaller screens */
  }

  .Arrangment-description p {
    font-size: 0.9rem !important; /* Adjust font size for smaller screens */
    width: 100% !important;
    
  }

  .Arrangment-description button {
    width: 150px; /* Adjust button width for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
@media (max-width: 576px) {
  .Arrangment-hero {
    height: 60vh; /* Adjust the height for better visibility on mobile */
  }

  .Arrangment-description h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .Arrangment-description p {
    font-size: 1rem; /* Adjust font size for smaller screens */
   
  }

  .Arrangment-description button {
    width: 150px; /* Adjust button width for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Arrangment-description {
    padding: 30px;
  }

  .Arrangment-hero {
    height: 70vh;
  }

  .Arrangment-description h1 {
    font-size: 2.2rem;
  }

  .Arrangment-description p {
    font-size: 1.1rem;
    width: 100%;
  }

  .Arrangment-description button {
    width: 180px;
    font-size: 18px;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Arrangment-description {
    padding: 40px;
  }

  .Arrangment-hero {
    height: 80vh;
  }

  .Arrangment-description h1 {
    font-size: 2.5rem;
  }

  .Arrangment-description p {
    font-size: 1.2rem;
  }

  .Arrangment-description button {
    width: 200px;
    font-size: 20px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Arrangment-description {
    padding: 50px;
  }

  .Arrangment-hero {
    height: 90vh;
  }

  .Arrangment-description h1 {
    font-size: 2.8rem;
  }

  .Arrangment-description p {
    font-size: 1.3rem;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .Arrangment-description button {
    width: 220px;
    font-size: 22px;
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .Arrangment-description {
    padding: 60px;
  }

  .Arrangment-hero {
    height: 100vh;
  }

  .Arrangment-description h1 {
    font-size: 2rem;
  }

  .Arrangment-description p {
    font-size:1.3rem ;
  }

  .Arrangment-description button {
    width: 250px;
    font-size: 24px;
  }
}
.contact-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 30%;
    gap: 30%;
    padding-left: 30px;
    padding-right: 30px;
}
  .form-control{
    width: 600px;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    color: #000;
    font-size: 18px;
    margin-bottom: 20px;
    
 
   
  }
 
  



  .form-input{
    margin-right: auto;
    margin-top:  auto;
    margin-bottom: auto;

  }
  input{
    height: 45px;
  }
  form .submit{
    background: #9B2515;
    border-color: transparent;
    color: #FDE0CA ;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    height: 50px;
    margin-top: 20px;
    border-radius: 25px;
  }
  form .submit:hover{
    background-color: #9B2515;
    opacity: 90%;
    cursor: pointer;
  }
  #sec-5m{
    display: none;
  }

  h1{
    color: #9B2515;
    font-family: Nordeco;
    font-size: 32px;
    font-weight: 400;

  }
  p{
    color:#5E4C40;
    font-family: Nordeco;
    font-size: 20px;
    font-weight: 200;
   
  }
  /* Extra Small devices (phones) */
  @media (max-width: 480px) {
    p{
      color:#5E4C40;
      font-family: Nordeco;
      font-size: 19px;
      font-weight: 200;
     
    }
  }
@media (max-width: 576px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    grid-gap: 1rem;
    gap: 1rem; /* Reduce the gap between elements */
  }

  .form-control {
    width: 90%; /* Adjust the width for better fit on mobile */
    margin-left: 0; 
   
  }

  .form-data {
    margin-bottom: 1rem;
   
  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
    width: 100%;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    grid-gap: 0rem;
    gap: 0rem; /* Reduce the gap between elements */
  
  }

  .form-control {
   max-width: 90%;
    margin-left: 5%; 
   
  }

  .form-data {
    margin-bottom: 1rem;

  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
    width: 100%;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the form vertically on mobile */
    grid-gap: 0rem;
    gap: 0rem; /* Reduce the gap between elements */
  }

  .form-control {
   
    margin-left: 0; 
   
  }

  .form-data {
    margin-bottom: 1rem;
    margin-left:15%; /* Add some margin for spacing */
  }

  .form-input {
    margin-right: 0; /* Remove right margin */
    text-align: center;
  }
  form .submit{
    font-size: 1rem;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-form {
    grid-gap: 0px;
    gap: 0px;
    padding: 20px;
  }
  .form-control {
    font-size: 20px;
  }
  h1 {
    font-size: 48px;
  }
}
@media (min-width: 1200px) {
  .contact-form {
    grid-gap: 30%;
    gap: 30%;
    padding: 30px;
   
  }
  .form-control {
    font-size: 20px;
   
  }
  h1 {
    font-size: 48px;
  }
  .form-data{
    font-size:2rem;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.Timesbackground img {
    width: 100%;
    -webkit-filter: blur(3px);
            filter: blur(3px); 
  }
  

  .Timesbackground {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    position: relative;
  }
  
  .openingtimes {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.822);
    width: 50%;
    height: auto;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    grid-gap: 5%; 
    gap: 5%;
  }

  .Timesbackground p {
    font-size: 30px;
  }

 
  .Timesbackground h1,
.Timesbackground p {
  margin: 5px;
}
@media (max-width: 480px) {
  .openingtimes {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.822);
    width: 50%;
height: auto;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
  }
  .background img{
    height: 300px;
  }

}
/* Extra Small devices (phones) */
@media (max-width: 576px) {
  .openingtimes {
    width: 90%; /* Adjust the width for better fit on mobile */
    max-width: 100%; /* Remove maximum width on mobile */
  }

  .Timesbackground h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
  }

  .Timesbackground p {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767px) {
  .openingtimes {
    width: 80%; /* Adjust the width for better fit on phones */
  }

  .Timesbackground h1 {
    font-size: 28px; /* Adjust font size for phones */
  }

  .Timesbackground p {
    font-size: 20px; /* Adjust font size for phones */
  }
}
/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .openingtimes {
    width: 70%; /* Adjust the width for better fit on tablets */
  }

  .Timesbackground h1 {
    font-size: 36px; /* Adjust font size for tablets */
  }

  .Timesbackground p {
    font-size: 24px; /* Adjust font size for tablets */
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .openingtimes {
    width: 60%; /* Adjust the width for better fit on desktops */
  }

  .Timesbackground h1 {
    font-size: 40px; /* Adjust font size for desktops */
  }

  .Timesbackground p {
    font-size: 28px; /* Adjust font size for desktops */
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .openingtimes {
    width: 50%; /* Adjust the width for better fit on large desktops */
  }

  .Timesbackground h1 {
    font-size: 44px; /* Adjust font size for large desktops */
  }

  .Timesbackground p {
    font-size: 32px; /* Adjust font size for large desktops */
  }
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #73806e;
    padding: 20px;
   
    
  }
  
  .logo-img {
    width: 100px;
    height: auto;
  }
  
  .footer p {
    display: flex;
    align-items: center;
    font-family: Nordeco;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #d8cec1;
    margin-left: auto;
  }
  
  .social-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    font-size: 20px;
    margin-left: 5px;
    color: #d8cec1;
  }
  
.headervacatures{
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.headervacatures img {
    max-width: 100%;
    display: block; /* Use 'block' instead of 'flex' */
    margin: 0 auto; /* Center horizontally */
    padding-left: 30px;
    padding-right: 30px;

}
.info{
    padding-right:30px;
    padding-left: 30px;
}
.vacature-paragraph
{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    
}
.vacature-paragraph h2{
    color:#9B2515;
}
.vacature-paragraph p{
  
    color:black;

}
.vacatures {
    display: flex;
    flex-direction: row;
    grid-gap: 10%;
    gap: 10%;
}
.vacatures h2{
    color:#9B2515;
    font-size: 2rem;
}
.vacatures img{
    padding-left: 30px;
    padding-right: 30px;

    
}
.chef{
    display: flex;
    flex-direction: row;
    grid-gap: 20%;
    gap: 20%;
}
.chef img{
    padding-left: 30px;
    padding-right: 30px;

    padding-bottom: 2%;
}
.chef h2{
    color:#9B2515;
    font-size: 2rem;
}
.chef-info{
    padding-left: 5%;
}
.soliciteer-btn{
    border-radius: 25px;
    background: #9B2515;
    border: none;
    color: white;
    width: 150px;
    height: 40px;
    color: #FFF;
    font-size: 20px;
    font-family: Nordeco;
   margin-top: 5%;
}
@media (max-width: 480px) {
    .vacatures,
    .chef {
      flex-direction: column;
    }
  
    .info,
    .chef-info {
      text-align: left;
      padding-left: 30px;
      padding-right: 30px;
  
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;
padding-left: 30px;
padding-right: 30px;

    }
    .vacature-paragraph h2{
        width: 100% !important;
text-align: left !important;
padding-left: 30px;
padding-right: 30px;


    }
    .headervacatures img {
        width: 100%;
      }
  
  }
  @media (min-width: 480px) and (max-width: 576px) {
    .vacatures,
    .chef {
      flex-direction: column;
    }
    .chef img{
        padding-left: 5%;
        padding-bottom: 2%;
    }
    .info,
    .chef-info {
      text-align: left;
      padding-left: 2%;
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;
padding-left: 5%;
    }
    .vacature-paragraph h2{
        width: 100% !important;
text-align: left !important;
padding-left: 10%;

    }
    .headervacatures img {
        width: 100%;
      }
  }

  @media (min-width: 576px) and (max-width: 768px) {

    .vacatures,
    .chef {
      flex-direction: column;
    }
    .chef img{
        padding-left: 20%;
        max-width: 60%;
        padding-bottom: 2%;
    }
    .vacatures img{
        padding-left: 20%;
        max-width: 60%;
    }
    .info,
    .chef-info {
      text-align: left;
      
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;

    }
    .vacature-paragraph h2{
       
text-align: left !important;


    }
    .headervacatures img {
        width: 100%;
      }
  }
  @media (min-width: 768px) and (max-width: 991px) {

    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
    
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
  }
  @media (min-width: 1200px) {

    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
  }
  
  @media (min-width: 1500px)  {
    
  }
.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.img-container img {
    max-width: 100%;
    height: auto;
}

.voorwaarden-info {
    background-color: #FDE0CA;
    margin-bottom: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.voorwaarden-info p {
    font-size: 1.2rem;
    width: 70%;
}

@media (max-width: 480px) {
    .img-container,
    .voorwaarden-info {
        height: auto;
    }
    
    .voorwaarden-info p {
        width: 90%;
    }
}

@media (min-width: 480px) and (max-width: 576px) {
    .voorwaarden-info p {
        width: 80%;
    }
   
}

@media (min-width: 576px) and (max-width: 768px) {
    .voorwaarden-info p {
        width: 75%;
    }
   
}

@media (min-width: 768px) and (max-width: 991px) {
    .voorwaarden-info p {
        width: 70%;
    }
    .voorwaarden-info {
        padding: 30px; /* Adjust as needed */
    }
   
}

@media (min-width: 992px) and (max-width: 1199px) {
    .voorwaarden-info p {
        width: 65%;
    }
    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
}

