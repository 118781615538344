.headervacatures{
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.headervacatures img {
    max-width: 100%;
    display: block; /* Use 'block' instead of 'flex' */
    margin: 0 auto; /* Center horizontally */
    padding-left: 30px;
    padding-right: 30px;

}
.info{
    padding-right:30px;
    padding-left: 30px;
}
.vacature-paragraph
{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    
}
.vacature-paragraph h2{
    color:#9B2515;
}
.vacature-paragraph p{
  
    color:black;

}
.vacatures {
    display: flex;
    flex-direction: row;
    gap: 10%;
}
.vacatures h2{
    color:#9B2515;
    font-size: 2rem;
}
.vacatures img{
    padding-left: 30px;
    padding-right: 30px;

    
}
.chef{
    display: flex;
    flex-direction: row;
    gap: 20%;
}
.chef img{
    padding-left: 30px;
    padding-right: 30px;

    padding-bottom: 2%;
}
.chef h2{
    color:#9B2515;
    font-size: 2rem;
}
.chef-info{
    padding-left: 5%;
}
.soliciteer-btn{
    border-radius: 25px;
    background: #9B2515;
    border: none;
    color: white;
    width: 150px;
    height: 40px;
    color: #FFF;
    font-size: 20px;
    font-family: Nordeco;
   margin-top: 5%;
}
@media (max-width: 480px) {
    .vacatures,
    .chef {
      flex-direction: column;
    }
  
    .info,
    .chef-info {
      text-align: left;
      padding-left: 30px;
      padding-right: 30px;
  
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;
padding-left: 30px;
padding-right: 30px;

    }
    .vacature-paragraph h2{
        width: 100% !important;
text-align: left !important;
padding-left: 30px;
padding-right: 30px;


    }
    .headervacatures img {
        width: 100%;
      }
  
  }
  @media (min-width: 480px) and (max-width: 576px) {
    .vacatures,
    .chef {
      flex-direction: column;
    }
    .chef img{
        padding-left: 5%;
        padding-bottom: 2%;
    }
    .info,
    .chef-info {
      text-align: left;
      padding-left: 2%;
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;
padding-left: 5%;
    }
    .vacature-paragraph h2{
        width: 100% !important;
text-align: left !important;
padding-left: 10%;

    }
    .headervacatures img {
        width: 100%;
      }
  }

  @media (min-width: 576px) and (max-width: 768px) {

    .vacatures,
    .chef {
      flex-direction: column;
    }
    .chef img{
        padding-left: 20%;
        max-width: 60%;
        padding-bottom: 2%;
    }
    .vacatures img{
        padding-left: 20%;
        max-width: 60%;
    }
    .info,
    .chef-info {
      text-align: left;
      
    }
    .vacature-paragraph p{
width: 100% !important;
text-align: left !important;

    }
    .vacature-paragraph h2{
       
text-align: left !important;


    }
    .headervacatures img {
        width: 100%;
      }
  }
  @media (min-width: 768px) and (max-width: 991px) {

    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
    
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
  }
  @media (min-width: 1200px) {

    .vacatures,
    .chef {
      flex-direction: row;
    }
    .chef img{
        padding-left: 0;
        max-width: 40%;
        padding-bottom: 2%;
    }
    .vacatures img{

        max-width: 40%;
    }
  }
  
  @media (min-width: 1500px)  {
    
  }