.about{
    padding-right:30px;
    padding-left: 30px;
}

.grid-h1 {
    font-weight: 400;
    font-size: 64px;
    color: #9B2515;
   

  }
  
  .grid-p {
width: 100%;
    margin-left: 40%;
    font-size: 1.3rem ;
  }
  
  .about-img {
    /* Add the following styles to create an offset effect */
    transform: translate(30px, 0px); /* Adjust the translation values as per your preference */
  }
  .about-img1 {
    /* Add the following styles to create an offset effect */
    transform: translate(30px, 200px); /* Adjust the translation values as per your preference */
  }
  /* Extra Small devices (phones) */
@media (max-width: 576px) { 
  .grid-h1 {
    font-size: 32px; /* Adjust the heading font size for mobile */
  }

  .grid-p {
    margin-left: 0; /* Remove left margin for full-width text on mobile */
  }

  .about-images-container {
    flex-direction: column; /* Stack images below each other on mobile */
    align-items: center; /* Center the images horizontally */
  }

  .about-img1,
  .about-img {
   display:none;
  }
}
@media (min-width: 577px) and (max-width: 767px) {
  .grid-h1 {
    font-size: 32px; /* Adjust the heading font size for mobile */
  }
.about{
  display: flex;
  justify-content: center;
}
  .grid-p {
    margin-left: 0; /* Remove left margin for full-width text on mobile */
  }

  .about-images-container {
    flex-direction: column; /* Stack images below each other on mobile */
    align-items: center; /* Center the images horizontally */
  }

  .about-img1,
  .about-img {
   display:none;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 899px) {
  .grid-h1 {
    font-size: 42px;
    margin-left: 30px;
  }

  .grid-p {
    margin-left: 30px;
  }

  .about-img,
  .about-img1 {
    display: none;
    /* Revert the offset effect for images on tablet devices */
    transform: none;
  }
}

/* Large devices (desktops) */
@media (min-width: 900px) and (max-width: 1199px) {
  .grid-h1 {
    font-size: 48px;
    padding-right:30px;
    padding-left: 30px;
  }

  .grid-p {
    margin-left: 30px;
  }

  .about-img,
  .about-img1 {
    display: block;
    /* Revert the offset effect for images on desktop devices */
   
  }
  .about-img1 {
    /* Add the following styles to create an offset effect */
    transform: translate(30px, 150px); /* Adjust the translation values as per your preference */
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .grid-h1 {
    font-size: 56px;
    margin-left: 100px;
  }

  .grid-p {
    margin-left: 100px;
    font-size:1.3rem ;
  }

  .about-img,
  .about-img1 {
    display: block;
    /* Revert the offset effect for images on large desktop devices */
 
  }
}