.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #73806e;
    padding: 20px;
   
    
  }
  
  .logo-img {
    width: 100px;
    height: auto;
  }
  
  .footer p {
    display: flex;
    align-items: center;
    font-family: Nordeco;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #d8cec1;
    margin-left: auto;
  }
  
  .social-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    font-size: 20px;
    margin-left: 5px;
    color: #d8cec1;
  }
  