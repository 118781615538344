.Timesbackground img {
    width: 100%;
    filter: blur(3px); 
  }
  

  .Timesbackground {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    position: relative;
  }
  
  .openingtimes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.822);
    width: 50%;
    height: auto;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    gap: 5%;
  }

  .Timesbackground p {
    font-size: 30px;
  }

 
  .Timesbackground h1,
.Timesbackground p {
  margin: 5px;
}
@media (max-width: 480px) {
  .openingtimes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.822);
    width: 50%;
height: auto;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
  }
  .background img{
    height: 300px;
  }

}
/* Extra Small devices (phones) */
@media (max-width: 576px) {
  .openingtimes {
    width: 90%; /* Adjust the width for better fit on mobile */
    max-width: 100%; /* Remove maximum width on mobile */
  }

  .Timesbackground h1 {
    font-size: 32px; /* Adjust font size for smaller screens */
  }

  .Timesbackground p {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
/* Small devices (phones) */
@media (min-width: 576px) and (max-width: 767px) {
  .openingtimes {
    width: 80%; /* Adjust the width for better fit on phones */
  }

  .Timesbackground h1 {
    font-size: 28px; /* Adjust font size for phones */
  }

  .Timesbackground p {
    font-size: 20px; /* Adjust font size for phones */
  }
}
/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .openingtimes {
    width: 70%; /* Adjust the width for better fit on tablets */
  }

  .Timesbackground h1 {
    font-size: 36px; /* Adjust font size for tablets */
  }

  .Timesbackground p {
    font-size: 24px; /* Adjust font size for tablets */
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .openingtimes {
    width: 60%; /* Adjust the width for better fit on desktops */
  }

  .Timesbackground h1 {
    font-size: 40px; /* Adjust font size for desktops */
  }

  .Timesbackground p {
    font-size: 28px; /* Adjust font size for desktops */
  }
}

/* Extra Large devices (large desktops) */
@media (min-width: 1200px) {
  .openingtimes {
    width: 50%; /* Adjust the width for better fit on large desktops */
  }

  .Timesbackground h1 {
    font-size: 44px; /* Adjust font size for large desktops */
  }

  .Timesbackground p {
    font-size: 32px; /* Adjust font size for large desktops */
  }
}